import { useMemo } from "react";
import { ComplianceTaskType } from "@/components/serviceFlow/settings/complianceHub/complianceTasksCardsList";
import { useComplianceTasksQuery } from "@/graphql/queries/complianceHub/complianceTasks.graphql.types";
import { useMedspaId } from "@/hooks/common/useMedspaId";
import {
  ComplianceTask,
  isComplianceTaskIncomplete,
} from "@/utils/complianceHub";

function useComplianceTasksData(config?: { skip?: boolean }) {
  const medspaId = useMedspaId();
  const { data } = useComplianceTasksQuery({
    variables: { medspaId },
    fetchPolicy: "cache-and-network",
    skip: config?.skip,
  });

  const tasks = useMemo(() => {
    const complianceTasks = data?.medspaComplianceTasks;
    return {
      msoTasks: complianceTasks?.msoTasks ?? [],
      pcTasks: complianceTasks?.pcTasks ?? [],
      providerBusinessTasks: complianceTasks?.providerTasks,
    };
  }, [data]);

  const issues = useMemo(() => {
    const countIncompleteRequired = (taskList: ComplianceTask[]) =>
      taskList.filter((task) => isComplianceTaskIncomplete(task)).length;

    return {
      msoIssues: tasks.msoTasks ? countIncompleteRequired(tasks.msoTasks) : 0,
      pcIssues: tasks.pcTasks ? countIncompleteRequired(tasks.pcTasks) : 0,
    };
  }, [tasks]);

  const rows = useMemo(() => {
    if (!tasks.providerBusinessTasks) return [];

    const { providerBusinessTasks } = tasks;

    return providerBusinessTasks.providers.map((provider) => {
      const name = `${provider.providerFullName}`;

      const provierComplianceTaskByTemplatesId =
        provider.complianceTasks?.reduce((acc, task) => {
          acc[task.complianceTaskTemplate.id] = task;
          return acc;
        }, {});

      const values = providerBusinessTasks.complianceTaskTemplates
        ?.map((taskTemplate) => {
          const complianceTask: ComplianceTaskType =
            provierComplianceTaskByTemplatesId[taskTemplate.id];

          return {
            complianceTask,
          };
        })
        .filter(({ complianceTask }) => !!complianceTask);

      return {
        providerId: provider.providerId,
        name,
        values,
      };
    });
  }, [tasks]);

  const providerBusinessTableData = useMemo(() => {
    if (!rows.length) return { columns: [], rows: [] };

    return {
      columns: [
        { id: "providerName", label: "Provider name" },
        ...(tasks.providerBusinessTasks?.complianceTaskTemplates?.map(
          ({ id, name }) => ({ id: `${id}`, label: name })
        ) ?? []),
      ],
      rows: rows || [],
    };
  }, [rows, tasks]);

  const providerBusinessIssues = useMemo(() => {
    if (!rows) return 0;

    return rows
      .flatMap((row) => row.values)
      .map(({ complianceTask }) => complianceTask)
      .filter(isComplianceTaskIncomplete).length;
  }, [rows]);

  return {
    msoTasks: tasks.msoTasks,
    pcTasks: tasks.pcTasks,
    msoIssues: issues.msoIssues,
    pcIssues: issues.pcIssues,
    providerBusinessTableData,
    providerBusinessIssues,
    incompleteTasksCount:
      issues.msoIssues + issues.pcIssues + providerBusinessIssues,
    isLoading: !data,
  };
}

export default useComplianceTasksData;
