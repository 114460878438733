import {
  TimePicker as MuiTimePicker,
  renderMultiSectionDigitalClockTimeView,
} from "@mui/x-date-pickers";
import { isValid, parse, set } from "date-fns";
import { forwardRef } from "react";
import { GREY, PRIMARY } from "@/config/mui/colorPalette";
import { ClockIcon } from "../icons";

export type TimePickerProps = React.ComponentProps<typeof MuiTimePicker> & {
  onAccept?: (value: Date | null) => void;
};

const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>((props, ref) => (
  <MuiTimePicker
    {...props}
    ref={ref}
    sx={{
      ...props.sx,
      "& .MuiInputBase-root.Mui-focused .MuiIconButton-root path": {
        stroke: PRIMARY,
      },
    }}
    slots={{
      ...props.slots,
      toolbar: null,
      openPickerIcon: () => <ClockIcon color={GREY[70]} />,
    }}
    closeOnSelect
    slotProps={{
      ...props.slotProps,
      dialog: {
        onClose: () => void undefined,
      },
      mobilePaper: {
        sx: {
          minWidth: "150px !important",
          "& .MuiMultiSectionDigitalClock-root": {
            borderLeft: "none !important",
          },
          '& .MuiMultiSectionDigitalClock-root  [aria-label="Select meridiem"]::after':
            {
              content: "none",
            },
          "& .MuiButtonBase-root": {
            borderRadius: "3px",
            width: "60px",
          },
          "& .MuiList-root": {
            width: "70px",
            overflowY: "auto !important",
          },
        },
      },
      layout: {
        sx: {
          padding: 1,
        },
      },
      textField: {
        ...props.slotProps?.textField,
        onBlur: (e) => {
          const inputValue = e.target.value;
          const baseDate =
            props.value instanceof Date && isValid(props.value)
              ? props.value
              : new Date();

          try {
            const parsedTime = parse(inputValue, "hh:mm a", new Date());

            const newDate = set(baseDate, {
              hours: parsedTime.getHours(),
              minutes: parsedTime.getMinutes(),
              seconds: 0,
              milliseconds: 0,
            });

            props.onAccept?.(newDate);
          } catch (error) {
            props.onAccept?.(null);
          }
        },
      },
    }}
    viewRenderers={{
      hours: renderMultiSectionDigitalClockTimeView,
      minutes: renderMultiSectionDigitalClockTimeView,
      seconds: renderMultiSectionDigitalClockTimeView,
    }}
  />
));

TimePicker.displayName = "TimePicker";
export default TimePicker;
