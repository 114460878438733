import {
  differenceInHours,
  endOfDay,
  isEqual,
  max,
  min,
  startOfDay,
} from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { ParsedUrlQuery } from "querystring";
import { useMemo } from "react";
import { BookingStep, TimeSlot } from "@/types/bookingTypes";
import { MedspaInfo } from "@/views/booking/bookingView";

type BasicProvider = {
  id: string;
};

type BasicService = {
  id: string;
  name: string;
  price: string;
  durationInMinutes: number;
  eligibleProviders: {
    id: string;
    userMedspa: {
      id: string;
    };
  }[];
};

// returns providers eligible to perform all of currently selected services
export const useEligibleProviders = <
  Provider extends BasicProvider,
  Service extends BasicService,
>(
  selectedServiceIds: string[],
  availableProviders?: Provider[],
  availableServices?: Service[]
) =>
  useMemo(
    () =>
      getEligibleProviders(
        availableServices?.filter((service) =>
          selectedServiceIds.includes(service.id)
        ),
        availableProviders
      ),
    [availableProviders, availableServices, selectedServiceIds]
  );

export const getEligibleProviders = <
  Provider extends BasicProvider,
  Service extends BasicService,
>(
  services?: Service[],
  availableProviders?: Provider[]
) =>
  services?.reduce((currentCommonProviders, service) => {
    const serviceEligibleProviderIds = service.eligibleProviders.map(
      (provider) => provider.userMedspa.id
    );
    return currentCommonProviders?.filter((userMedspa) =>
      serviceEligibleProviderIds.includes(userMedspa.id)
    );
  }, availableProviders) || [];

// returns services (ids) that can not be performed by currently available providers
export const useDisabledServiceIds = <
  Provider extends BasicProvider,
  Service extends BasicService,
>(
  eligibleProviders?: Provider[],
  availableServices?: Service[]
) =>
  useMemo(() => {
    const canBePerformed = (service: Service) =>
      service.eligibleProviders.some((serviceProvider) =>
        eligibleProviders?.some(
          (provider) => provider.id === serviceProvider.userMedspa.id
        )
      );

    return availableServices
      ?.filter((service) => !canBePerformed(service))
      .map((service) => service.id);
  }, [eligibleProviders, availableServices]);

export const getDefaultBookingState = (
  medspaInfo: MedspaInfo,
  query: ParsedUrlQuery
) => {
  // get properties from URL query params
  const servicesIds = getServiceIdsFromQuery(query);
  const providerId = query.providerId as string;
  const isCompleted = !!query.completed;
  const date = (query.date as string) || null;
  const timeSlotStart = query.startStr as string;
  const timeSlotEnd = query.endStr as string;

  const firstName = query.firstName as string;
  const lastName = query.lastName as string;
  const email = query.email as string;
  const phone = query.phone as string;
  const isReturningClient = query.returningClient as string;
  const isFds = query.isFds as string;

  const clientDetails =
    firstName && lastName && email && phone
      ? {
          firstName,
          lastName,
          email,
          phone,
          comment: "",
        }
      : null;
  // validate & match properties from the URL to accurate services/providers data from graphql
  const selectedServices = medspaInfo.serviceMenuItems.filter((service) =>
    servicesIds.includes(service.id)
  );
  const eligibleProviders = getEligibleProviders(
    selectedServices,
    medspaInfo.userMedspas
  );
  const canPerformAllServices = eligibleProviders.length > 0;
  const possibleSelectedServices = canPerformAllServices
    ? selectedServices
    : [];
  const selectedProvider =
    eligibleProviders.find((provider) => provider.id === providerId) ||
    eligibleProviders?.[0] ||
    null;

  const timeSlot: TimeSlot =
    timeSlotStart && timeSlotEnd
      ? {
          startStr: timeSlotStart,
          endStr: timeSlotEnd,
        }
      : null;

  const getStepFromState = (): BookingStep => {
    if (possibleSelectedServices.length === 0) return BookingStep.SERVICES;
    if (!selectedProvider || !providerId) return BookingStep.PROVIDER_SELECTION;
    if (isCompleted) return BookingStep.BOOKED;
    if (isReturningClient) return BookingStep.CODE_INPUT;
    if (clientDetails && timeSlot) return BookingStep.FRONT_DESK_BOOKING;

    return BookingStep.AVAILABILITY;
  };

  const step = getStepFromState();

  const state = {
    selectedServices: canPerformAllServices ? selectedServices : [],
    selectedProvider,
    date,
    timeSlot,
    step,
    clientDetails,
    isFds: isFds === "true",
  };

  return state;
};

export type DefaultBookingState = ReturnType<typeof getDefaultBookingState>;

const getServiceIdsFromQuery = (query: ParsedUrlQuery) =>
  query.serviceIds ? (query.serviceIds as string).split(",") : [];

export const getAvailabilityValues = (
  selectedDate: Date,
  medspaTimezone: string,
  minPossibleDate?: string,
  maxPossibleDate?: string
) => {
  const fromStartDate = max([
    new Date(minPossibleDate),
    startOfDay(selectedDate),
  ]);
  const toEndDate = min([new Date(maxPossibleDate), endOfDay(selectedDate)]);

  const addOffsetToStart = !isEqual(fromStartDate, new Date(minPossibleDate));
  const addOffsetToEnd = !isEqual(toEndDate, new Date(maxPossibleDate));

  const localTimeTZOffset = (selectedDate.getTimezoneOffset() / 60) * -1;

  const medspaTZOffset =
    -1 *
    Math.floor((getTimezoneOffset(medspaTimezone) / (1000 * 60 * 60)) % 24);

  // Calculate offset to add/substract to difference in hours depending on the
  // local machine timezone offset and the medspa timezone offset
  const offset = medspaTZOffset + localTimeTZOffset;

  let from = differenceInHours(fromStartDate, new Date(), {
    roundingMethod: "floor",
  });
  let to = differenceInHours(toEndDate, new Date(), {
    roundingMethod: "floor",
  });

  if (addOffsetToStart) from += offset;
  if (addOffsetToEnd) to += offset;

  return {
    from,
    to,
  };
};

export enum BookingReturningClientErrors {
  MULTIPLE_CLIENTS_EXITS = "multiple-clients-exist",
  TOO_MANY_ATTEMPTS = "too-many-attempts",
  INCORRECT_CODE = "incorrect-code",
  CODE_EXPIRED = "code-expired",
  CLIENT_NOT_FOUND = "client-not-found",
  INACTIVE_CLIENT = "inactive-client",
}

export enum ClientBookingErrors {
  PROVIDER_UNAVAILABLE = "Cannot book appointment - provider is unavailable.",
  BOOKING_ALREADY_EXISTS = "A booking or reservation already exists",
  DEPOSIT_CARD_NOT_FOUND = "Your card was not found. Please try again.",
  DEPOSIT_CARD_DECLINED = "Your card was declined. Please try again.",
  DEPOSIT_PAYMENT_FAILED = "There was an error processing your payment. Please try again.",
}

export const DEPOSIT_ERRORS = [
  ClientBookingErrors.DEPOSIT_CARD_NOT_FOUND,
  ClientBookingErrors.DEPOSIT_CARD_DECLINED,
  ClientBookingErrors.DEPOSIT_PAYMENT_FAILED,
];
