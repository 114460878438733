import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

export type DrawerContextType = {
  readonly isOpen: boolean;
  open: VoidFunction;
  close: VoidFunction;
};

const DrawerContext = createContext<DrawerContextType>(undefined);

export type UseDrawerProps = {
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  open?: boolean;
};

const useDrawer = (
  { onOpen, onClose, open }: UseDrawerProps,
  ref?: React.ForwardedRef<DrawerContextType>
) => {
  const [isOpen, setIsOpen] = useState(open);
  useEffect(() => {
    setIsOpen(open); //* make sure to always reflect the prop value, since it's prioritized
  }, [open]);

  const handleClose = useCallback(() => {
    if (isOpen) {
      /**
       * Only call setIsOpen when the drawer is controlled by the context provider
       * Otherwise, the drawer will be closed by external actions
       */
      if (!onClose) {
        setIsOpen(false);
      }
      onClose?.();
    }
  }, [onClose, isOpen, setIsOpen]);

  const handleOpen = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      onOpen?.();
    }
  }, [onOpen, isOpen, setIsOpen]);

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
      isOpen,
    }),
    [isOpen, handleOpen, handleClose]
  );

  return {
    isOpen,
    open: handleOpen,
    close: handleClose,
  };
};

export const useDrawerContext = () => {
  const context = React.useContext(DrawerContext);

  if (!context) {
    throw new Error("useDrawerContext must be used inside a DrawerProvider");
  }

  return context;
};

const DrawerProvider: React.ForwardRefRenderFunction<
  DrawerContextType,
  React.PropsWithChildren<UseDrawerProps>
> = ({ children, ...useDrawerProps }, ref) => {
  const drawer = useDrawer(useDrawerProps, ref);

  return (
    <DrawerContext.Provider value={drawer}>{children}</DrawerContext.Provider>
  );
};

export default forwardRef(DrawerProvider);
