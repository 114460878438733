import { BusinessHoursInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addMinutes, isEqual, isValid } from "date-fns";
import { partition } from "lodash";
import { Dispatch, useRef, useState } from "react";
import AdditionalInfo from "@/components/common/additionalInfo/additionalInfo";
import { interactiveCalendarProps } from "@/components/common/calendar/interactiveCalendarProps";
import MoxieFullCalendar from "@/components/common/calendar/moxieFullCalendar";
import TimePicker from "@/components/common/datetimePicker/TimePicker";
import InfoCard from "@/components/common/infoCard/infoCard";
import PageContainer from "@/components/common/pageContainer/pageContainer";
import PageHeader from "@/components/common/pageHeader/pageHeader";
import { useConfirm } from "@/components/providers/confirmProvider";
import CreateEventNavigation from "@/components/serviceFlow/visits/createVisit/createEventNavigation";
import { AvailableResourcesQuery } from "@/graphql/queries/availableResources.graphql.types";
import useCalendarGoToDate from "@/hooks/calendar/useCalendarGoToDate";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import useDatesValidation from "@/hooks/misc/useDatesValidation";
import useResizeObserver from "@/hooks/misc/useResizeObserver";
import { Resource } from "@/hooks/visits/useCreateVisit";
import {
  DEFAULT_CALENDAR_HEADER_HEIGHT,
  DEFAULT_PAGE_PADDING_BOTTOM,
  getCalendarHeight,
} from "@/utils";
import { formattedTime } from "@/utils/date";

const BOTTOM_BAR_PADDING = 16;

const SelectTime = ({
  onBack,
  start,
  setStart,
  end,
  setEnd,
  servicesDuration,
  totalVisitDuration,
  events,
  primaryAction,
  completeActionTitle,
  disableCompleteAction = false,
  secondaryAction,
  providerBusinessHours,
  resources,
}: {
  onBack: () => void;
  start: Date;
  setStart: Dispatch<Date>;
  end: Date;
  setEnd: Dispatch<Date>;
  servicesDuration: number;
  totalVisitDuration: number;
  primaryAction: () => Promise<void>;
  events: Array<{
    title: string;
    display?: string;
    start: string;
    end: string;
  }>;
  completeActionTitle: string;
  disableCompleteAction?: boolean;
  secondaryAction?: {
    title: string;
    url?: string;
    onClick?: () => Promise<void>;
  };
  providerBusinessHours?: BusinessHoursInput;
  resources: AvailableResourcesQuery["availableResources"];
}) => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const infoBoxRef = useRef<HTMLDivElement | null>(null);
  const [infoBoxHeight, setInfoBoxHeight] = useState(0);
  const { medspaReadableTimezone } = useMedspaTimezone();
  const { goToDate } = useCalendarGoToDate(calendarRef);
  const usesResources =
    resources?.serviceDevices?.length || resources?.medspaRooms?.isRoomRequired;

  const { getConfirm } = useConfirm();

  const { isTimeCorrect } = useDatesValidation(start, end, setEnd);

  useResizeObserver(infoBoxRef, ({ height }) => setInfoBoxHeight(height + 40));

  const handleCalendarGoToDate = (date: Date) => {
    goToDate(date);
    const updatedDate = calendarRef.current?.getApi().getDate();

    if (!updatedDate) return;

    const hours = start.getHours();
    const minutes = start.getMinutes();
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    setStart(updatedDate);
  };

  const confirmResourceOverbooking = async (action: () => Promise<void>) => {
    const roomOverBooked =
      resources?.medspaRooms?.availableRooms === 0 &&
      resources?.medspaRooms?.isRoomRequired;

    const deviceOverBooked = resources?.serviceDevices?.some(
      (device) => device?.availableDevices === 0
    );

    if (roomOverBooked || deviceOverBooked) {
      let overBookedResources = "";
      if (roomOverBooked && deviceOverBooked) {
        overBookedResources = "device and room";
      } else {
        overBookedResources = roomOverBooked ? "room" : "device";
      }

      const ok = await getConfirm({
        title: `You are over booking a ${overBookedResources}`,
        description: `You are double booking a ${overBookedResources} with this appointment. Are you sure you want to continue with this booking?`,
        discardButtonText: "No, go back",
        confirmButtonText: "Confirm booking",
      });

      if (!ok) return;
    }
    await action();
  };

  return (
    <PageContainer
      sx={{
        minHeight: "100vh",
        background: "background.paper",
        pb: secondaryAction ? 19 : 10,
      }}
    >
      <PageHeader
        title="Select time"
        goBackStrategy="onClick"
        goBackOnClick={onBack}
      />
      {usesResources && (
        <Box
          ref={infoBoxRef}
          sx={{
            mb: 2,
            position: "sticky",
            top: 40,
            zIndex: 10,
            backgroundColor: "background.default",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <ResourcesAdditionalInfo resources={resources} />
        </Box>
      )}
      <Box sx={{ overflow: "auto" }}>
        <InfoCard
          sx={{
            mb: 2,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mb: 2.5,
              }}
            >
              <TimePicker
                value={start}
                label="Start time"
                onAccept={(value) => {
                  const date = value as Date;

                  isValid(date) ? setStart(date) : null;
                }}
              />
              <TimePicker
                value={end}
                label="End time"
                onAccept={(value) => {
                  const date = value as Date;

                  isValid(date) && !isEqual(date, end) ? setEnd(date) : null;
                }}
                minTime={addMinutes(start, 1)}
              />
            </Stack>
          </LocalizationProvider>
          <Grid container rowSpacing={2}>
            <Grid size={6}>
              <Typography sx={{ color: "gray.main" }}>
                Service duration
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="subtitleDefault">
                {formattedTime(servicesDuration)}
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography sx={{ color: "gray.main" }}>
                Total visit duration
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="subtitleDefault">
                {formattedTime(totalVisitDuration)}
              </Typography>
            </Grid>
          </Grid>
        </InfoCard>
        <Typography
          sx={{
            my: 1,
          }}
        >
          Please note: All appointments are in{" "}
          <strong>{medspaReadableTimezone}</strong> time
        </Typography>
        <InfoCard sx={{ mt: 2 }}>
          <MoxieFullCalendar
            calendarRef={calendarRef}
            handleCalendarGoToDate={handleCalendarGoToDate}
            height={getCalendarHeight(
              68,
              DEFAULT_CALENDAR_HEADER_HEIGHT,
              secondaryAction
                ? 2 * DEFAULT_PAGE_PADDING_BOTTOM - BOTTOM_BAR_PADDING
                : DEFAULT_PAGE_PADDING_BOTTOM,
              infoBoxHeight
            )}
            initDate={start as Date}
            initialView="timeGridDay"
            {...interactiveCalendarProps}
            editable={false}
            events={events}
            eventChange={(info) => {
              const { start, end } = info.event;
              if (start && end) {
                setStart(start);
                setEnd(end);
              }
            }}
            businessHours={providerBusinessHours}
          />
        </InfoCard>
      </Box>
      <CreateEventNavigation
        title={completeActionTitle}
        onCreateEventClick={() => confirmResourceOverbooking(primaryAction)}
        disabled={disableCompleteAction || !isTimeCorrect}
        secondaryAction={
          secondaryAction
            ? {
                ...secondaryAction,
                onClick: async () => {
                  if (secondaryAction.onClick) {
                    await confirmResourceOverbooking(secondaryAction.onClick);
                  }
                },
              }
            : undefined
        }
      />
    </PageContainer>
  );
};

export function ResourcesAdditionalInfo({
  resources,
}: {
  resources: Resource;
}) {
  const devices = resources?.serviceDevices;
  const rooms = resources?.medspaRooms;

  const resourcesAdded = (devices && devices.length) || rooms;

  if (!resources) return <Skeleton variant="rounded" sx={{ height: 64 }} />;
  if (!resourcesAdded) return null;

  const [availableDevices, unavailableDevices] = partition(
    devices,
    (device) => device && device.availableDevices > 0
  );

  const roomRequired = rooms?.isRoomRequired;
  const singleDevice = availableDevices.length === 1;
  const roomAvailable = rooms?.availableRooms && rooms.availableRooms > 0;
  const multipleDevices = availableDevices.length > 1;
  const hasUnavailableResources =
    unavailableDevices.length > 0 || (!roomAvailable && roomRequired);

  const getUsageAndAvailabilityText = () => {
    let usageText = "This appointment uses";

    if (roomRequired) {
      usageText += " 1 room";
    }

    if (availableDevices.length > 0) {
      if (roomRequired) {
        usageText += " and";
      }
      if (singleDevice) {
        usageText += ` 1 ${availableDevices[0]?.serviceTypeName} device`;
      } else if (multipleDevices) {
        usageText += " the following devices:";
        availableDevices.forEach((device) => {
          usageText += `\n  • ${device?.serviceTypeName} device`;
        });
      }
    }

    usageText += ". ";

    if (multipleDevices) {
      usageText += "\nEverything is available at this time.";
    } else if (roomRequired && roomAvailable && singleDevice) {
      usageText += "Both are available at this time.";
    } else if (roomRequired && roomAvailable) {
      usageText += "There is a room free at this time.";
    } else if (singleDevice && !roomRequired) {
      usageText += "The device is free at this time.";
    }

    return (
      <Typography component="span" style={{ whiteSpace: "pre-wrap" }}>
        {usageText}
      </Typography>
    );
  };

  return (
    <>
      {unavailableDevices.map((device) => (
        <AdditionalInfo
          key={device?.serviceTypeId}
          variant="error"
          text={`${device?.serviceTypeName} Device, which is required to perform your selected service(s), is not available during the selected time.`}
        />
      ))}
      {rooms?.availableRooms === 0 && roomRequired && (
        <AdditionalInfo
          variant="error"
          text="There are no available rooms during this time."
        />
      )}
      {!hasUnavailableResources && (
        <AdditionalInfo variant="info" text={getUsageAndAvailabilityText()} />
      )}
    </>
  );
}

export default SelectTime;
