// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";
import { TelehealthFieldsFragmentDoc } from "../../fragments/telehealth/telehealthFields.graphql.types";
import { LastChartSignatureFragmentDoc } from "../../fragments/visit/lastChartSignature.graphql.types";

const defaultOptions = {} as const;
export type VisitDropdownFieldsFragment = {
  __typename?: "visit";
  id: string;
  status: string;
  isVisitTelehealth?: boolean | null;
  invoice?: { __typename?: "invoice"; id: string; status: string } | null;
  appointment?: {
    __typename?: "SchedulingAppointment";
    id: string;
    startTime?: string | null;
  } | null;
  client: {
    __typename?: "client";
    id: string;
    pendingGfeReview?: Array<{
      __typename?: "GfeReviewRequest";
      id: string;
      asyncReviewedAt?: string | null;
    }> | null;
    lastGfeReview?: Array<{
      __typename?: "GfeReviewRequest";
      id: string;
    }> | null;
  };
  telehealthVisitDetails?: {
    __typename?: "TelehealthVisitDetails";
    visitId: string;
    visitType: string;
    videoLink?: string | null;
    visitDetails?: string | null;
  } | null;
  lastSignature: Array<{ __typename?: "VisitsChartsignature"; id: string }>;
};

export type ChargeFeeAdditionalInfoQueryVariables = Types.Exact<{
  visitId: Types.Scalars["bigint"]["input"];
}>;

export type ChargeFeeAdditionalInfoQuery = {
  __typename?: "query_root";
  visitByPk?: {
    __typename?: "visit";
    id: string;
    created: string;
    medspa: {
      __typename?: "medspa";
      id: string;
      configuration?: {
        __typename?: "MedspasMedspaconfiguration";
        cancellationFeePolicyEnabled: boolean;
        noShowFeeValue: number;
        lateCancellationAdvanceHours: number;
        additionalInfo?: {
          __typename?: "MedSpaConfigurationType";
          lastEditedNoShowFeeAt?: string | null;
        } | null;
      } | null;
    };
    client: {
      __typename?: "client";
      id: string;
      stripeData?: {
        __typename?: "ClientStripeType";
        id: string;
        stripeCardsList: Array<{
          __typename?: "MoxieCardType";
          id?: string | null;
        } | null>;
      } | null;
    };
  } | null;
};

export const VisitDropdownFieldsFragmentDoc = gql`
  fragment VisitDropdownFields on visit {
    id
    status
    invoice {
      id
      status
    }
    appointment {
      id
      startTime
    }
    client {
      id
      pendingGfeReview {
        id
        asyncReviewedAt
      }
      lastGfeReview {
        id
      }
    }
    ...TelehealthFields
    ...LastChartSignature
  }
  ${TelehealthFieldsFragmentDoc}
  ${LastChartSignatureFragmentDoc}
`;
export const ChargeFeeAdditionalInfoDocument = gql`
  query ChargeFeeAdditionalInfo($visitId: bigint!) {
    visitByPk(id: $visitId) {
      id
      created
      medspa {
        id
        configuration {
          cancellationFeePolicyEnabled
          noShowFeeValue
          lateCancellationAdvanceHours
          additionalInfo {
            lastEditedNoShowFeeAt
          }
        }
      }
      client {
        id
        stripeData {
          id
          stripeCardsList {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useChargeFeeAdditionalInfoQuery__
 *
 * To run a query within a React component, call `useChargeFeeAdditionalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeFeeAdditionalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeFeeAdditionalInfoQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useChargeFeeAdditionalInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeFeeAdditionalInfoQuery,
    ChargeFeeAdditionalInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChargeFeeAdditionalInfoQuery,
    ChargeFeeAdditionalInfoQueryVariables
  >(ChargeFeeAdditionalInfoDocument, options);
}
export function useChargeFeeAdditionalInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeFeeAdditionalInfoQuery,
    ChargeFeeAdditionalInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChargeFeeAdditionalInfoQuery,
    ChargeFeeAdditionalInfoQueryVariables
  >(ChargeFeeAdditionalInfoDocument, options);
}
export function useChargeFeeAdditionalInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ChargeFeeAdditionalInfoQuery,
    ChargeFeeAdditionalInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChargeFeeAdditionalInfoQuery,
    ChargeFeeAdditionalInfoQueryVariables
  >(ChargeFeeAdditionalInfoDocument, options);
}
export type ChargeFeeAdditionalInfoQueryHookResult = ReturnType<
  typeof useChargeFeeAdditionalInfoQuery
>;
export type ChargeFeeAdditionalInfoLazyQueryHookResult = ReturnType<
  typeof useChargeFeeAdditionalInfoLazyQuery
>;
export type ChargeFeeAdditionalInfoSuspenseQueryHookResult = ReturnType<
  typeof useChargeFeeAdditionalInfoSuspenseQuery
>;
export type ChargeFeeAdditionalInfoQueryResult = Apollo.QueryResult<
  ChargeFeeAdditionalInfoQuery,
  ChargeFeeAdditionalInfoQueryVariables
>;
