import { isBefore } from "date-fns";
import toast from "react-hot-toast";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useSetVisitStatusMutation } from "@/graphql/mutations/setVisitStatus.graphql.types";
import { useChargeFeeAdditionalInfoLazyQuery } from "@/graphql/queries/visit/visitDropdown.graphql.types";
import { VisitStatus } from "@/types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";
import useChargeNoShowFee from "./useChargeNoShowFee";

export const FEE_CHANGED_MODAL_CONFIG = {
  title: "Fee amount has changed",
  description:
    "The no-show fee value has changed since the appointment was created. The client could have consented to a different fee value. Are you sure you want to proceed with charging the new fee?",
  confirmButtonText: "Proceed",
  discardButtonText: "Cancel",
};

export default function useMarkAsNoShow(visitId: string) {
  const logError = useErrorLogger();
  const { getConfirmWithCheckbox, getConfirm } = useConfirm();
  const [setVisitStatus] = useSetVisitStatusMutation();
  const chargeNoShowFee = useChargeNoShowFee(visitId);
  const [getNoShowFeeInfo] = useChargeFeeAdditionalInfoLazyQuery({
    variables: {
      visitId,
    },
  });

  const handleMarkAsNoShow = async () => {
    let toastId;
    try {
      const { data } = await getNoShowFeeInfo();
      const hasCardsOnFile =
        data.visitByPk.client.stripeData.stripeCardsList.length > 0;
      const cancellationFeePolicyEnabled =
        data.visitByPk.medspa.configuration.cancellationFeePolicyEnabled;
      const noShowFeeValue = data.visitByPk.medspa.configuration.noShowFeeValue;
      const visitCreatedDate = data.visitByPk.created;
      const lastEditedNoShowFeeAt =
        data.visitByPk.medspa.configuration.additionalInfo
          .lastEditedNoShowFeeAt;

      await setVisitStatus({
        variables: {
          id: visitId,
          status: VisitStatus.NO_SHOW,
        },
      });

      if (!cancellationFeePolicyEnabled) {
        return;
      }

      if (!hasCardsOnFile) {
        toast.error(
          "Unable to charge no-show fee - client has no card on file."
        );
        return;
      }

      const hasFeeChanged = isBefore(
        new Date(visitCreatedDate),
        new Date(lastEditedNoShowFeeAt)
      );

      if (hasFeeChanged) {
        const ok = await getConfirm(FEE_CHANGED_MODAL_CONFIG);

        if (!ok) {
          return;
        }
      }

      const { confirmed, checkboxValue: sendCommunication } =
        await getConfirmWithCheckbox(
          {
            title: "Confirm charging no-show fee?",
            description: `Charge a $${noShowFeeValue} no-show fee to credit card on file?`,
            confirmButtonText: "Yes",
            discardButtonText: "No",
          },
          {
            defaultValue: true,
            label:
              "Send e-mail and text to the client with this information and receipt",
          }
        );

      if (!confirmed) {
        return;
      }

      const toastId = toast.loading("Preparing to charge the no-show fee...");

      await chargeNoShowFee(sendCommunication);
      toast.success("Entering the payment flow to charge the no-show fee...", {
        id: toastId,
      });
    } catch (errors) {
      toast.error(
        hasDjangoMutationError(errors)
          ? errors.message
          : "Unable to charge the no-show fee. Please contact our support team.",
        { id: toastId }
      );
      logError(errors);
    }
  };

  return handleMarkAsNoShow;
}
