import Cookies from "js-cookie";
import { omit } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUser } from "@/auth/useUser";
import { Option } from "@/components/common/sorting/radioOptionsTypes";

export enum CalendarViews {
  LIST = "list",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

const CalendarViewsOptions: Option[] = [
  {
    id: CalendarViews.LIST,
    label: "List view",
  },
  {
    id: CalendarViews.DAY,
    label: "Daily calendar view",
  },
  {
    id: CalendarViews.WEEK,
    label: "Weekly calendar view",
  },
  {
    id: CalendarViews.MONTH,
    label: "Monthly calendar view",
  },
];

export const CALENDAR_VIEW_KEY = "CALENDAR_VIEW";

export default function useCalendarTypeView(defaultCalendarView: string) {
  const { push, query } = useRouter();
  const { view } = query;

  const defaultView = defaultCalendarView as CalendarViews;

  const { medspa } = useUser();

  const [selectedCalendarView, setSelectedCalendarView] = useState<Option>(
    () => {
      const selectedOption = CalendarViewsOptions.find(
        (option) => option.id === defaultView
      );
      if (!selectedOption) {
        return CalendarViewsOptions[0];
      }
      return selectedOption;
    }
  );

  const onOptionChange = (newOption: Option) => {
    setSelectedCalendarView(newOption);
  };

  useEffect(() => {
    if (view !== selectedCalendarView.id) {
      Cookies.set(CALENDAR_VIEW_KEY, selectedCalendarView.id);

      // Preserve existing query parameters while updating the view parameter
      // Remove medspaId from the query parameters.
      // NextJS includes this in query, but not needed for url params
      const updatedQuery = {
        ...omit(query, ["medspaId"]),
        view: selectedCalendarView.id,
      };

      // We went for query params because we want to to a shallow refresh and not a full page reload
      // Since we already have a dynamic route in this root ([slug]) we can't use a dynamic route for
      // calendar views.
      // https://nextjs.org/docs/pages/building-your-application/routing/linking-and-navigating#caveats
      push(
        {
          pathname: `/${medspa}/visits`,
          query: updatedQuery,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [medspa, push, selectedCalendarView, view, query]);

  return {
    activeOptionId: selectedCalendarView.id,
    options: CalendarViewsOptions,
    onOptionChange,
  };
}
