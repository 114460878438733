import { StateCreator, StoreApi, UseBoundStore } from "zustand";
import {
  FilterSliceState,
  createFilterSlice,
} from "@/store/views/globalFilters/filterSlice";
import { ServiceMenuItemGfeStatus } from "@/types/gfe";

export type GfeStatusFilterState = ServiceMenuItemGfeStatus[];

export const GFE_OPTIONS = [
  ServiceMenuItemGfeStatus.FORMS_INCOMPLETE,
  ServiceMenuItemGfeStatus.PENDING_REVIEWER,
  ServiceMenuItemGfeStatus.CONTRAINDICATED,
  ServiceMenuItemGfeStatus.NOT_INDICATED,
  ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS,
  ServiceMenuItemGfeStatus.INDICATED,
];

type GfeStatusFilterStore = FilterSliceState<GfeStatusFilterState> & {
  toggleGfeStatus: (id: ServiceMenuItemGfeStatus, checked: boolean) => void;
  selectAllGfeStatuses: (statuses: ServiceMenuItemGfeStatus[]) => void;
};

export const createGfeStatusFilterStore: StateCreator<GfeStatusFilterStore> = (
  ...args
) => {
  const [set] = args;

  return {
    ...createFilterSlice<GfeStatusFilterState>(GFE_OPTIONS)(...args),
    toggleGfeStatus: (id, checked) => {
      set((prevState) => {
        const { currentFilter } = prevState;

        return {
          currentFilter: checked
            ? [...currentFilter, id]
            : currentFilter.filter((existingId) => existingId !== id),
        };
      });
    },
    selectAllGfeStatuses: (statuses: ServiceMenuItemGfeStatus[]) => {
      set(() => ({ currentFilter: statuses }));
    },
    clearFilter: () => {
      set(() => ({ currentFilter: [] }));
    },
  };
};

export type UseGfeStatusFilterStore = UseBoundStore<
  StoreApi<GfeStatusFilterStore>
>;

export const getAppliedGfeStatusFilter = (state: GfeStatusFilterStore) =>
  state.appliedFilter;
