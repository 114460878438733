export const PUBLIC_ROUTES: string[] = [
  "/",
  "/403",
  "/404",
  "/500",
  "/sign-in(.*)",
  "/booking/:slug*",
  "/online-store/:medspaSlug*",
  "/client/:medspaSlug*",
  "/url/:uuid",
  "/api/health",
  "/.well-known/apple-app-site-association",
  "/.well-known/assetlinks.json",
];

const PUBLIC_ROUTES_REGEX = PUBLIC_ROUTES.map(
  (route) =>
    new RegExp(
      "^" +
        route
          .replace(/:\w+\*/g, ".*") // Replace dynamic segments with ".*"
          .replace(/\(\.\*\)/g, ".*") + // Replace (.*) with .*
        "$"
    )
);

export const isPublicRoute = (pathname: string): boolean => {
  return PUBLIC_ROUTES_REGEX.some((regex) => regex.test(pathname));
};
