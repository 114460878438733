export function formatCurrency({
  amount,
  currency = "USD",
  showCents = true,
}: {
  amount: number;
  currency?: string;
  showCents?: boolean;
}) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    ...(!showCents && { maximumFractionDigits: 0 }),
  }).format(amount);
}

export function pixelsToNumber(pixels: string) {
  return pixels.replace("px", "");
}

/**
 * This function converts the keys of an object to camelCase.
 * This is useful when dealing with API responses that use snake_case for keys.
 *
 * @example
 * ```ts
 * const response = { user_name: "John", address_details: { street_name: "Main" } };
 * const camelCaseResponse = camelCaseKeys(response);
 * console.log(camelCaseResponse);
 * // Result: { userName: "John", addressDetails: { streetName: "Main" } }
 * ```
 */
export const camelCaseKeys = <T extends Record<string, unknown>>(
  obj: T | string
): T => {
  // If it's a string that looks like JSON, try to parse it
  if (typeof obj === "string") {
    try {
      obj = JSON.parse(obj) as T;
    } catch {
      return {} as T; // Return empty object if parsing fails
    }
  }

  // Return empty object for null, undefined, or non-objects
  if (typeof obj !== "object" || obj === null) {
    return {} as T;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => camelCaseKeys(v)) as unknown as T;
  }

  return Object.keys(obj).reduce((result, key) => {
    const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
      letter.toUpperCase()
    );
    const value = obj[key] as unknown;

    result[camelKey as keyof T] = (
      typeof value === "object" && value !== null
        ? camelCaseKeys(value as Record<string, unknown>)
        : value
    ) as T[keyof T];
    return result;
  }, {} as T);
};
