import { useApolloClient } from "@apollo/client";
import { useAuth } from "@clerk/nextjs";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { AuthUser, mapUserData } from "@/auth/useUser";
import {
  ProviderDisplayNameQuery,
  useProviderDisplayNameLazyQuery,
} from "@/graphql/queries/providerDisplayName.graphql.types";
import {
  UserQuery,
  UserQueryResult,
  useUserLazyQuery,
} from "@/graphql/queries/user.graphql.types";
import { getDefaultRoute } from "@/utils";
import useErrorLogger from "@/utils/useErrorLogger";
import { LoginError } from "@/views/landingView";

const checkUserForErrors = (user: AuthUser): LoginError | null => {
  if (!user) {
    return {
      title: "Couldn't decode the user token",
      description: "Please contact Moxie support.",
    };
  }

  if (!user.id) {
    // When user id does not exist, it means clerk user id
    // is not associated with any user in our database
    return {
      title: "No account exists for this user",
      description: "Please contact Moxie support.",
    };
  }

  if (user.role === null) {
    // When user role is null, it means the default role metadata for the user in clerk is not set
    return {
      title: "There is an issue with the account configuration for this user",
      description: "Please contact Moxie support.",
    };
  }

  return null;
};

const checkUserMedspaForErrors = (
  userMedspaData: UserQueryResult
): LoginError => {
  if (!userMedspaData?.data) {
    return {
      title: "Something went wrong",
      description: "Please contact Moxie support.",
    };
  }

  if (isEmpty(userMedspaData.data.userMedspa)) {
    return {
      title: "This account is not connected to any medspa",
      description:
        "Please contact your provider support manager to get access to Moxie Suite.",
    };
  }

  return null;
};

type UserMedspas = UserQueryResult["data"]["userMedspa"];
type UserMedspa = UserMedspas[number];

type UseAuthStateResult = {
  user: AuthUser | null;
  providerData: ProviderDisplayNameQuery | null;
  userMedspaData: UserQuery | null;
  activeUserMedspa: UserMedspa | null;
  isLoading: boolean;
  error: LoginError | null;
  onMedspaChange: (medspaId: string) => void;
};

export const useAuthState = (): UseAuthStateResult => {
  const logError = useErrorLogger();
  const apollo = useApolloClient();
  const router = useRouter();
  const { isLoaded: isClerkLoaded, isSignedIn, userId, getToken } = useAuth();

  const [user, setUser] = useState<AuthUser>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<{
    title: string;
    description: string;
  } | null>(null);

  const [activeUserMedspa, setActiveUserMedspa] = useState<UserMedspa>(null);

  const [getProviderData, { data: providerData }] =
    useProviderDisplayNameLazyQuery();
  const [getUserMedspaData, { data: userMedspaData }] = useUserLazyQuery();

  useEffect(() => {
    if (!isClerkLoaded) return;

    if (!isSignedIn && !userId) {
      setUser(null);
      setActiveUserMedspa(null);
      setError(null);
      setLoading(false);
      apollo.clearStore();

      if (!router.pathname.startsWith("/sign-in")) {
        router.push("/sign-in");
      }
    }
    // I don't want to run this effect when router changes (router.path update also updates the router object),
    // since some pages use redirect strategy and this can cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClerkLoaded, apollo, userId, isSignedIn]);

  useEffect(() => {
    if (!isClerkLoaded) return;

    const fetchUser = async () => {
      setLoading(true);

      try {
        const userData = await mapUserData(getToken);

        const userError = checkUserForErrors(userData);
        if (userError) {
          setError(userError);
          return;
        }

        const userId = userData.id;

        await getProviderData({
          variables: { id: userId },
        });

        const userMedspaDataResult = await getUserMedspaData({
          variables: { id: userId },
        });

        const userMedspaError = checkUserMedspaForErrors(userMedspaDataResult);

        if (userMedspaError) {
          setError(userMedspaError);
          return;
        }

        const getUserMedspa = (userMedspa: UserMedspa) =>
          userMedspa.medspa.id === router.query.medspaId;

        const userMedspa = userMedspaDataResult.data.userMedspa;
        const selectedUserMedspa =
          userMedspa.find(getUserMedspa) || userMedspa[0];

        setUser(userData);
        setActiveUserMedspa(selectedUserMedspa);

        if (router.query.medspaId !== selectedUserMedspa.medspa.id) {
          // Don't redirect if the user is already on the correct page (medspa matches)
          // For example, user entered the page manually, via email link, etc.
          router.push(getDefaultRoute(selectedUserMedspa));
        }
      } catch (e) {
        setError({
          title: "Something went wrong",
          description: "Please try again later.",
        });

        logError(e);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      setUser(null);
      setActiveUserMedspa(null);
      setError(null);

      fetchUser();
    }

    // I don't want to run this effect when router changes (router.path update also updates the router object),
    // since some pages use redirect strategy and this can cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isClerkLoaded,
    userId,
    logError,
    getProviderData,
    getUserMedspaData,
    getToken,
  ]);

  const handleMedspaChange = useCallback(
    (medspaId: string) => {
      setActiveUserMedspa(
        userMedspaData.userMedspa.find((um) => um.medspa.id === medspaId) ||
          userMedspaData.userMedspa[0]
      );
    },
    [userMedspaData]
  );

  if (!isSignedIn) {
    return {
      user: null,
      providerData: null,
      userMedspaData: null,
      activeUserMedspa: null,
      isLoading: loading,
      error: null,
      onMedspaChange: handleMedspaChange,
    };
  }

  return {
    user,
    providerData,
    userMedspaData,
    activeUserMedspa,
    isLoading: loading,
    error,
    onMedspaChange: handleMedspaChange,
  };
};
