// START ENVIRONMENTS
export const ENVIRONMENTS = {
  LOCAL: "local",
  QA: `qa`,
  STAGING: `staging`,
  PRODUCTION: `production`,
} as const;

export type ENVIRONMENT = (typeof ENVIRONMENTS)[keyof typeof ENVIRONMENTS];

export const PUBLIC_ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENV || ENVIRONMENTS.LOCAL;

export const IS_DEVELOPMENT = {
  [ENVIRONMENTS.LOCAL]: true,
  [ENVIRONMENTS.QA]: true,
  [ENVIRONMENTS.STAGING]: true,
  [ENVIRONMENTS.PRODUCTION]: false,
}[PUBLIC_ENVIRONMENT];

export const IS_PRODUCTION = PUBLIC_ENVIRONMENT === ENVIRONMENTS.PRODUCTION;
// END ENVIRONMENTS

export const DATE_FORMATS = {
  SLASH_DATE: "M/d/yyyy", // 9/19/2023
  DATE_PICKER: "MM/dd/yyyy", // 09/19/2023
  TILE: "EEE, MMM d", // Tue, Sep 19
  CALENDAR: "EEE, MMMM d", // Tuesday, September 19
  DEFAULT: "PPP", // September 19th, 2023
  SERVER_DATE: "yyyy-MM-dd", // 2023-09-19
  SHORT_DATE: "PP", // Sep 19, 2023
  SHORT_MONTH_DAY: "MMM d", // Sep 19
  SHORT_WEEKDAY: "EEE, d", // Tue, 19
  DAY_OF_WEEK: "EEEE", // Tuesday
};

export const TIME_FORMATS = {
  HOUR_MINUTE_PICKER: "HH:mm", // 14:30
  BOOKING: "p", // 2:30 PM
  TILE: "hh:mm aaa", // 02:30 pm
  COMPACT: "h:mmaaa", // 2:30pm
  COMPACT_SPACE: "h:mm aaa", // 2:30 pm
};

export const DATE_TIME_FORMATS = {
  DEFAULT: "P hh:mm aaa", // 9/19/2023 02:30 pm
  DEFAULT_COMMA: "M/dd/yyyy, hh:mm aaa", // 9/19/2023, 02:30 pm
  DEFAULT_DOT: "M/d/yyyy • h:mma", // 9/19/2023 • 2:30PM
  TILE: "PPpp", // Sep 19, 2023, 2:30 PM
  BOOKING: "PPPP", // Tuesday, September 19th, 2023 at 2:30 PM
  SHORT_DATE_TIME: "PP - p", // Sep 19, 2023 - 2:30 PM
  SHORT_DATE_LONG_TIME: "eee, MMM d, yyyy h:mm a", // Tue, Sep 19, 2023 2:30 PM
};

export const AVATAR_COLORS = [
  "#C2D6D3",
  "#ADB1EB",
  "#F5F5A3",
  "#F5ABA3",
  "#A3EFF5",
];

export const HYPHEN = "–";

export const APP_URL_BASE = process.env.NEXT_PUBLIC_APP_URL_BASE;

export const MOXIE_HELP_DESK_URL =
  "https://help.joinmoxie.com/knowledge/moxie-suite";

export const FULLCALENDAR_LICENSE_KEY =
  process.env.NEXT_PUBLIC_FULLCALENDAR_LICENSE_KEY;

export const HASURA_CLIENT_TOKEN_KEY = "X-Hasura-Client-Access-Token";

export const MOXIE_SUPPORT_EMAIL = "support@joinmoxie.com";

export const MOXIE_HOME_PAGE_URL = "https://joinmoxie.com/";

export const MOXIE_HELP_DESK_2FA =
  "https://help.joinmoxie.com/knowledge/2fa-faq";

export const MOXIE_HELP_DESK_COLOR_CUSTOMIZATION =
  "https://help.joinmoxie.com/knowledge/how-to-customize-your-online-booking-link";

const getDjangoInternalUrl = () => {
  const baseUrl = process.env.NEXT_PUBLIC_DJANGO_URI;
  if (PUBLIC_ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
    return baseUrl.replace("api.", "api.prod.internal.");
  }
  if (PUBLIC_ENVIRONMENT === ENVIRONMENTS.STAGING) {
    return baseUrl.replace("api.staging.", "api.staging.internal.");
  }
  return baseUrl;
};

export const DJANGO_ADMIN_STOP_IMPERSONATION_URL = `${getDjangoInternalUrl()}/impersonate/stop/`;

export enum COLLECTIONS {
  ALLERGAN = "allergan",
  GALDERMA = "galderma",
  JEUVEAU = "jeuveau",
  MERZ = "merz",
  COSMOFRANCE = "cosmofrance",
  CROWN_AESTHETICS = "crown_aesthetics",
  REVANCE = "revance",
  PROLLENIUM = "prollenium",
  STRIVE = "strive",
}

type ShopifyConfig = {
  DOMAIN: string;
  TOKEN: string;
  COLLECTION_IDS: Record<COLLECTIONS, string>;
};

export const MOXIE_SHOPIFY_STORE_URL = "https://supplies.joinmoxie.com";

export const SHOPIFY_CONFIG: ShopifyConfig = {
  DOMAIN: "moxie-9681.myshopify.com",
  TOKEN: "5ac639afded3bb1dc06d0a178e6f6b24",
  COLLECTION_IDS: {
    [COLLECTIONS.ALLERGAN]: "464113598754",
    [COLLECTIONS.GALDERMA]: "464113565986",
    [COLLECTIONS.JEUVEAU]: "475501887778",
    [COLLECTIONS.MERZ]: "480449102114",
    [COLLECTIONS.COSMOFRANCE]: "480449364258",
    [COLLECTIONS.CROWN_AESTHETICS]: "480449397026",
    [COLLECTIONS.REVANCE]: "483878633762",
    [COLLECTIONS.PROLLENIUM]: "492660130082",
    [COLLECTIONS.STRIVE]: "494058733858",
  },
};

type PharmacyConfig = {
  formId: string;
  title: string;
  id: string;
  pharmacyId: string;
  logo: string;
};

export const PHARMACY_CONFIG: Record<string, PharmacyConfig> = {
  empower: {
    formId: "sV4vBDSLW5us",
    title: "Empower pharmacy",
    id: "empower",
    pharmacyId: "empower",
    logo: "/images/pharmacies/empower-logo.svg",
  },
  strive503a: {
    formId: "cVkU61hCZDus",
    title: "Strive pharmacy",
    id: "strive503a",
    pharmacyId: "strive503a",
    logo: "/images/pharmacies/strive-logo.svg",
  },
  strive503b: {
    formId: "iiYbATSiVius",
    title: "Strive pharmacy",
    id: "strive503b",
    pharmacyId: "strive503b",
    logo: "/images/pharmacies/strive-logo.svg",
  },
  test: {
    formId: "dKoG6PuXXhus",
    title: "Test pharmacy",
    id: "test",
    pharmacyId: "test",
    logo: "/assets/moxie-logo.svg",
  },
} as const;

export const STRIPE_MIN_PAYMENT_AMOUNT = 0.5;
export const AFFIRM_MIN_PAYMENT_AMOUNT = 50;
export const AFFIRM_MAX_PAYMENT_AMOUNT = 10000;

export const NEXT_PUBLIC_MAINTENANCE_MESSAGE =
  process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE;

export * from "@/config/avatar";

// We have a shared org id across all environments... no env var needed
export const COMMAND_AI_ORG_ID = "6319c990";
