import { useAuth } from "@clerk/nextjs";
import { Button, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ErrorMessage, {
  FullPageError,
} from "@/components/common/errors/errorMessage";
import { LoadingAnimation } from "@/components/signin/loadingAnimation";
import useMoxieLoginLongLoading from "@/hooks/login/useMoxieLoginLongLoading";

export type LoginError = { title: string; description: string };

function LandingView({ error }: { error?: LoginError }) {
  const { signOut } = useAuth();
  const router = useRouter();

  const [loginTimeout, setLoginTimeout] = useState(false);

  const { registerStartLoading, registerFinishLoading } =
    useMoxieLoginLongLoading(() => setLoginTimeout(true));

  useEffect(() => {
    registerStartLoading();

    return () => registerFinishLoading();
  }, [registerFinishLoading, registerStartLoading]);

  const handleBackToLogin = async () => {
    await signOut();
    router.push("/sign-in");
  };

  if (error)
    return (
      <FullPageError>
        <ErrorMessage
          title={error.title}
          description={error.description}
          customActionButtons={
            <Button variant="outlined" onClick={handleBackToLogin}>
              Back to Login
            </Button>
          }
        />
      </FullPageError>
    );

  return (
    <Stack
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
        minHeight: "90vh",
      }}
    >
      <LoadingAnimation title="Redirecting you to Moxie Suite" />
      {loginTimeout && (
        <Stack
          sx={{
            gap: 3,
          }}
        >
          <Typography
            sx={{
              color: "secondary.main",
              maxWidth: "400px",
              textAlign: "center",
            }}
          >
            Seems like this is taking longer than expected. You can report an
            issue or try with a different account.
          </Typography>
          <Stack
            sx={{
              gap: 3,
            }}
          >
            {typeof window !== "undefined" && window?.birdeatsbug && (
              <Button
                variant="contained"
                onClick={() => window.birdeatsbug.trigger()}
              >
                Report Issue
              </Button>
            )}
            <Button variant="outlined" onClick={handleBackToLogin}>
              Back to Login
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default LandingView;
