import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import {
  createClientFormDefaultValues,
  schema,
} from "@/components/serviceFlow/clientDetails/forms/clientFormHelpers";
import { useCreateClientByProviderMutation } from "@/graphql/mutations/clients.graphql.types";
import { handleApolloError } from "@/utils";
import { formatDateForServer } from "@/utils/date";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";
import { CreateClientInputs } from "@/views/serviceFlow/clients/createClientView";
import { addGraphQLError } from "@/views/serviceFlow/clients/editClientView";

type Options = {
  onSubmitSuccess?: (
    clientId: string,
    details: Omit<CreateClientInputs, "medspaId">
  ) => void;
};

export default function useCreateClientForm(options: Options = {}) {
  const { onSubmitSuccess } = options;
  const logError = useErrorLogger();
  const { medspa } = useUser();

  const [createClientMutation, { loading }] =
    useCreateClientByProviderMutation();

  const { handleSubmit, control, getValues, setValue, setError, trigger } =
    useForm<CreateClientInputs>({
      resolver: yupResolver(schema),
      defaultValues: createClientFormDefaultValues,
    });

  const onSubmit = async () => {
    const toastId = toast.loading("Creating a new client...");
    try {
      const clientFormData = getValues();
      clientFormData.email = clientFormData.email.toLowerCase();

      const { birthdate, ...details } = clientFormData;

      const state = details.state || "";

      const { data } = await createClientMutation({
        variables: {
          ...details,
          state,
          ...(birthdate && { birthdate: formatDateForServer(birthdate) }),
          medspaId: medspa,
        },
      });

      const { clientId } = data.createClientByProvider;

      onSubmitSuccess?.(clientId, details);

      toast.success("Client created!", { id: toastId });
    } catch (error) {
      const alternativeMessage = hasDjangoMutationError(error)
        ? error.message
        : "Failed to create the client";

      if (error instanceof ApolloError) {
        addGraphQLError({
          ...handleApolloError(error),
          setError,
          alternativeMessage,
          toastId,
        });
      } else {
        toast.error(alternativeMessage, { id: toastId });
      }

      if (!alternativeMessage.includes("already exists")) {
        logError(error);
      }
    }
  };

  return {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    trigger,
    onSubmit,
    loading,
  };
}
