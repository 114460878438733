import { differenceInHours } from "date-fns";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useCancelAppointmentMutation } from "@/graphql/mutations/cancelAppointment.graphql.types";
import { useCancelVisitWithoutAppointmentMutation } from "@/graphql/mutations/cancelVisitWithoutAppointment.graphql.types";
import { useSetVisitStatusMutation } from "@/graphql/mutations/setVisitStatus.graphql.types";
import {
  ChargeFeeAdditionalInfoQuery,
  useChargeFeeAdditionalInfoLazyQuery,
} from "@/graphql/queries/visit/visitDropdown.graphql.types";
import useChargeLateCancellationFee from "@/hooks/serviceFlow/visitDropdown/useChargeLateCancellationFee";
import useCurrentVisitDropdownFragment from "@/hooks/serviceFlow/visitDropdown/useCurrentVisitDropdownFragment";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import { PROVIDER, VisitStatus } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";

const CONFIRMATION_MODAL_CONTENT = {
  title: "Cancel the appointment?",
  description: "Are you sure you want to cancel the appointment?",
  discardButtonText: "Close",
  confirmButtonText: "Confirm",
};

const TOAST_CONTENT = {
  success: "Appointment cancelled",
  error: "Error occurred while cancelling the appointment. Please try again.",
  loading: "Cancelling the appointment...",
};

export default function useCancelAppointment(visitId: string) {
  const logError = useErrorLogger();
  const isProvider = useUserHasRoles([PROVIDER]);
  const { hasFeaturePermission, newPermissionsEnabledForUser } = useUser();
  const { data: visitData } = useCurrentVisitDropdownFragment(visitId);
  const [getChargeFeeData] = useChargeFeeAdditionalInfoLazyQuery({
    variables: {
      visitId,
    },
  });
  const [cancelAppointment] = useCancelAppointmentMutation();
  const [setVisitStatus] = useSetVisitStatusMutation();
  const showChargeLateCancellationFeeModal =
    useChargeLateCancellationFee(visitId);
  const { getConfirmWithCheckbox, getConfirm } = useConfirm();
  const [cancelVisitWithoutAppointment] =
    useCancelVisitWithoutAppointmentMutation();

  const canChargeLateCancellationFee = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.COMPLETE_VISIT)
    : isProvider;

  const CACHE_MODIFY_CONTENT = {
    id: `visit:${visitId}`,
    fields: {
      status() {
        return VisitStatus.CANCELLED;
      },
    },
  };

  const handleCancelVisitWithoutAppointment = async (visitId: string) => {
    const confirmed = await getConfirm(CONFIRMATION_MODAL_CONTENT);
    if (!confirmed) return;

    try {
      await toast.promise(
        cancelVisitWithoutAppointment({
          variables: {
            visitId,
          },
          update(cache) {
            cache.modify(CACHE_MODIFY_CONTENT);
          },
        }),
        TOAST_CONTENT
      );
    } catch (errors) {
      logError(errors);
    }
  };

  const handleLateCancellation = async (visitId: string) => {
    try {
      await toast.promise(
        setVisitStatus({
          variables: {
            id: visitId,
            status: VisitStatus.CANCELLED_LATE,
          },
          update(cache) {
            cache.modify({
              id: `visit:${visitId}`,
              fields: {
                status() {
                  return VisitStatus.CANCELLED_LATE;
                },
              },
            });
          },
        }),
        TOAST_CONTENT
      );
    } catch (errors) {
      logError(errors);
    }

    if (!canChargeLateCancellationFee) {
      return;
    }

    await showChargeLateCancellationFeeModal({
      discardButtonText: "Do not charge fee",
      confirmButtonText: "Yes charge",
    });
  };

  const handleCancelAppointment = async ({
    skipFeeChargeAttempt,
  }: {
    skipFeeChargeAttempt: boolean;
  }) => {
    if (!visitData?.appointment) {
      return await handleCancelVisitWithoutAppointment(visitId);
    }

    if (!skipFeeChargeAttempt) {
      const { data: chargeFeeData } = await getChargeFeeData();

      const isLateCancellation = checkIfItIsLateCancellation(
        visitData.appointment.startTime,
        chargeFeeData
      );

      if (isLateCancellation) {
        return await handleLateCancellation(visitId);
      }
    }

    const { confirmed, checkboxValue: sendCommunication } =
      await getConfirmWithCheckbox(CONFIRMATION_MODAL_CONTENT, {
        label: "Send e-mail and text to the client with this information",
        defaultValue: true,
      });

    if (!confirmed) return;

    try {
      await toast.promise(
        cancelAppointment({
          variables: {
            appointmentId: visitData.appointment.id,
            sendCommunication,
          },
          update(cache) {
            cache.modify(CACHE_MODIFY_CONTENT);
          },
        }),
        TOAST_CONTENT
      );
    } catch (errors) {
      logError(errors);
    }
  };
  return handleCancelAppointment;
}

export function checkIfItIsLateCancellation(
  visitStartTime: string,
  chargeFeeData: ChargeFeeAdditionalInfoQuery | undefined
) {
  return (
    differenceInHours(new Date(visitStartTime), new Date()) <
    chargeFeeData?.visitByPk?.medspa.configuration?.lateCancellationAdvanceHours
  );
}
