import { isEmpty, isEqual } from "lodash";
import { StateCreator, StoreApi, UseBoundStore } from "zustand";
import { DateRange } from "@/components/common/datetimePicker/dateRangePicker";
import { DatePeriods } from "@/components/common/filters/dateFilter/dateDialog";
import {
  createFilterSlice,
  FilterSliceState,
} from "@/store/views/globalFilters/filterSlice";
import {
  getNext30Days,
  getCurrentMonth,
  getCurrentWeek,
  getLastMonth,
  getLastWeek,
  getLastNDays,
} from "@/utils/date";

export type DateFilterState = {
  range: DateRange;
  period: DatePeriods;
};

const INITIAL_RANGE: DateRange = { startDate: null, endDate: null };
const EMPTY_FILTER: DateFilterState = {
  range: INITIAL_RANGE,
  period: null,
};
const INITIAL_FILTER: DateFilterState = EMPTY_FILTER;

export const getTimeRange = (period: DatePeriods) => {
  return {
    [DatePeriods.NEXT_30_DAYS]: getNext30Days(),
    [DatePeriods.CURRENT_WEEK]: getCurrentWeek(),
    [DatePeriods.LAST_WEEK]: getLastWeek(),
    [DatePeriods.CURRENT_MONTH]: getCurrentMonth(),
    [DatePeriods.LAST_MONTH]: getLastMonth(),
    [DatePeriods.LAST_3_DAYS]: getLastNDays(3),
    [DatePeriods.LAST_7_DAYS]: getLastNDays(7),
    [DatePeriods.LAST_30_DAYS]: getLastNDays(30),
  }[period];
};

export const INITIAL_MD_CHART_DATE_FILTER: DateFilterState = {
  range: getTimeRange(DatePeriods.LAST_MONTH),
  period: DatePeriods.LAST_MONTH,
};

export const INITIAL_PROVIDER_CHART_DATE_FILTER: DateFilterState = {
  range: getTimeRange(DatePeriods.LAST_7_DAYS),
  period: DatePeriods.LAST_7_DAYS,
};

type DateFilterStore = FilterSliceState<DateFilterState> & {
  changePeriod: (period: DatePeriods) => void;
  changeRange: (range: DateRange) => void;
};

export const createDateFilterStore =
  (
    initialFilter: DateFilterState = INITIAL_FILTER
  ): StateCreator<DateFilterStore> =>
  (...args) => {
    const [set, get] = args;

    return {
      ...createFilterSlice<DateFilterState>(initialFilter)(...args),
      changePeriod: (period) => {
        const range = getTimeRange(period);

        set({
          currentFilter: {
            period,
            range,
          },
        });
      },
      getIsEmpty: () => {
        const { currentFilter } = get();
        if (!currentFilter) return true;

        return (
          isEmpty(currentFilter.period) &&
          isEqual(currentFilter.range, INITIAL_RANGE)
        );
      },
      changeRange: (range) => {
        set({
          currentFilter: {
            period: null,
            range,
          },
        });
      },
      clearFilter: () => {
        set(() => ({
          currentFilter: EMPTY_FILTER,
        }));
      },
    };
  };

export type UseDateFilterStore = UseBoundStore<StoreApi<DateFilterStore>>;

export const getAppliedDateFilter = (state: DateFilterStore) =>
  state.appliedFilter;

export const getIsDateFilterDirty = (state: DateFilterStore) =>
  state.getIsDirty;
