// Medspa scoped feature flags (TODO, migrate the remaining global flags to this const)
export const FeatureFlags = {
  BALANCE_V1: "balance-v1",
  CHERRY_PAYMENTS_V1: "cherry-payments-v1",
  PACKAGES_ALLOW_RETAIL_PRODUCTS: "packages-allow-retail-products",
  MESSAGING_MAINTENANCE: "messaging-maintenance-v1",
  STANDING_ORDERS_V1: "standing-orders-v1",
  AFFIRM_PAYMENT_METHOD: "affirm-payment-method",
  VENMO_PAYMENT_METHOD: "venmo-payment-method",
  ZELLE_PAYMENT_METHOD: "zelle-payment-method",
  IS_IN_MAINTENANCE: "maintenance-banner-v1",
  CONSENT_FORM_IN_EVERY_APPT: "consent-form-in-every-appt",
  CONSENT_FORM_ENHANCEMENTS_V1: "consent-form-enhancements-v1",
  INVOICE_SUMMARY_UPDATE_SERVICES: "invoice-summary-update-services",
  COMPLIANCE_HUB_V1: "compliance-hub-v1",
  COMPLIANCE_HUB_V2: "compliance-hub-v2",
  COMPLIANCE_HUB_V3: "compliance-hub-v3",
  COMPLIANCE_HUB_V4: "compliance-hub-v4",
  COMPLIANCE_HUB_APPT_HISTORY: "compliance-hub-appt-history",
  COMPLIANCE_MD_MEETINGS_V1: "compliance-md-meetings-v1",
  SYNC_GFE_V2: "sync-gfe-v2",
  MARKETING_MESSAGES_V1: "marketing-messages-v1",
  NEW_BOOKING_FLOW_V1: "new-booking-flow-v1",
  REBOOKING_V2: "rebooking-v2",
  COMMAND_AI: "commandai",
  ONLINE_STORE_V2: "online-store-v2",
  MOXIE_EMAIL_GIFT_BUILDER_V1: "moxie-email-gift-builder-v1",
  MARKETING_MESSAGES_RESPONSE_V1: "marketing-messages-response-v1",
  TELEHEALTH_SERVICES_V1: "telehealth-services-v1",
  EDIT_INVOICE_LINE_PRICES_V1: "edit-invoice-line-prices-v1",
  ORDER_PRESCRIPTION_DRAWER_V1: "order-prescription-drawer-v1",
  CONFIGURABLE_PROVIDER_NOTIFICATIONS: "configurable-provider-notifications",
  CONFIGURABLE_PROVIDER_NOTIFICATIONS_V1:
    "configurable-provider-notifications-v1",
  SHOPIFY_PLUS: "shopify-plus",
  ONLINE_BOOKING_DEPOSITS_V1: "online-booking-deposits-v1",
  NEW_BOOKING_STEPPER_V1: "new-booking-stepper-v1",
} as const;

export type FeatureFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];
