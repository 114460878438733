import { datadogRum } from "@datadog/browser-rum";
import React, { Component, ReactNode } from "react";
import {
  AnalyticsContext,
  useAnalytics,
} from "@/components/common/segment/AnalyticsContext";
import ErrorBoundaryFallback from "./errorBoundaryFallback";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static contextType? = AnalyticsContext;

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    const analytics = this.context as ReturnType<typeof useAnalytics>;

    analytics?.track("Error Boundary Viewed", {
      errorMessage: error.message,
      errorInfo,
    });

    datadogRum.addError(error, {
      errorBoundary: true,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryFallback
          error={this.state.error}
          resetError={() => this.setState({ hasError: false, error: null })}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
