import {
  Autocomplete,
  Box,
  Button,
  debounce,
  Divider,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  ListSubheader,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import shadows from "@mui/material/styles/shadows";
import React, { useCallback, useEffect, useState } from "react";
import {
  ChevronSmallDown,
  CrossSmall,
  Search,
  User1,
} from "react-swm-icon-pack";
import { DEFAULT_CREATE_VISIT_DRAWER_WIDTH } from "@/components/booking/createVisitDrawer";
import TimeSelectorButton from "@/components/booking/timeSelector/timeSelectorButton";
import AdditionalInfo from "@/components/common/additionalInfo/additionalInfo";
import OutlinedButton from "@/components/common/buttons/outlinedButton";
import { DropdownMenuItems } from "@/components/common/dropdownMenu/dropdownMenu";
import {
  GFE_SERVICE_STATUS_ICON,
  mapToGfeChipType,
} from "@/components/common/gfe/gfeIndicationChip";
import { CamIcon, PlusSmallIcon } from "@/components/common/icons";
import UserHeart from "@/components/common/icons/UserHeart";
import { useConfirm } from "@/components/providers/confirmProvider";
import { getVariablePricingCopy } from "@/components/serviceFlow/common/groupedServicesSelectionList";
import { TelehealthDetailsFormProps } from "@/components/serviceFlow/visits/telehealthDetailsForm";
import TelehealthDetailsSection from "@/components/serviceFlow/visits/telehealthDetailsSection";
import {
  GREY,
  PRIMARY,
  VIOLET,
  TEXT_SECONDARY,
} from "@/config/mui/colorPalette";
import { CategoriesWithClientIndicationsQuery } from "@/graphql/queries/categories.graphql.types";
import {
  ClientForVisitCreation,
  Provider,
} from "@/hooks/visits/useCreateVisit";
import { AppointmentType, TelehealthType } from "@/types";
import { formattedPrice } from "@/utils";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import { REMOVE_TELEHEALTH_DETAILS_MODAL_CONTENT } from "@/utils/telehealth";

export type ServiceWithCategory =
  CategoriesWithClientIndicationsQuery["serviceCategory"][number]["medspaServiceMenuItems"][number] & {
    categoryData: {
      id: string;
      name: string;
    };
  };

type Props = {
  telehealthDetailsOpen: boolean;
  setTelehealthDetailsOpen: (isOpen: boolean) => void;
  client: ClientForVisitCreation;
  clients: ClientForVisitCreation[];
  isLoadingClients?: boolean;
  clientsSearchQuery?: string;
  handleSearchClientsInput?: ReturnType<
    typeof debounce<
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    >
  >;
  clientsSearchInputRef?: React.MutableRefObject<HTMLInputElement>;
  selectedServices: ServiceWithCategory[];
  disabledServiceIds: string[];
  servicesWithCategory: ServiceWithCategory[];
  provider: Provider;
  providers: Provider[];
  startDate: Date;
  isRebooking: boolean;
  areSelectedServicesSameApptType: boolean;
  onSelectClient: (client: ClientForVisitCreation) => void;
  onSelectServices: (servicesId: string[], startDate?: Date) => void;
  onSelectProvider: (provider: Provider) => void;
  onSelectTime: (start: Date, end: Date) => void;
  onRemoveService: (serviceId: string) => void;
  onCreateClient: () => void;
} & TelehealthDetailsFormProps;

export const CreateVisitDrawerForm = ({
  telehealthDetailsOpen,
  setTelehealthDetailsOpen,
  client,
  clients,
  isLoadingClients,
  clientsSearchQuery,
  clientsSearchInputRef,
  handleSearchClientsInput,
  selectedServices,
  disabledServiceIds,
  servicesWithCategory,
  provider,
  providers,
  startDate,
  isRebooking,
  telehealthType,
  telehealthVideoLink,
  telehealthAdditionalDetails,
  areSelectedServicesSameApptType,
  onSelectClient,
  onSelectServices,
  onSelectProvider,
  onRemoveService,
  onCreateClient,
  onChangeTelehealthType,
  onChangeTelehealthVideoLink,
  onChangeTelehealthAdditionalDetails,
  onChangeIsTelehealthVisitDetailsValid,
}: Props) => {
  const { getConfirm } = useConfirm();

  const [telehealthDetailsButtonDisabled, setTelehealthDetailsButtonDisabled] =
    useState<boolean>(false);

  const closeTelehealthDetailsSection = useCallback(() => {
    setTelehealthDetailsOpen(false);
    onChangeTelehealthType(undefined);
    onChangeTelehealthVideoLink(undefined);
    onChangeTelehealthAdditionalDetails(undefined);
  }, [
    setTelehealthDetailsOpen,
    onChangeTelehealthType,
    onChangeTelehealthVideoLink,
    onChangeTelehealthAdditionalDetails,
  ]);

  const handleTelehealthDetailsOpen = (): void => {
    onChangeTelehealthType(TelehealthType.VIDEO);
    setTelehealthDetailsOpen(true);
  };

  const handleTelehealthDetailsRemove = async () => {
    const confirm = await getConfirm(REMOVE_TELEHEALTH_DETAILS_MODAL_CONTENT);
    if (!confirm) return;

    closeTelehealthDetailsSection();
  };

  const telehealthDropdownActions: DropdownMenuItems = [
    {
      component: "Remove telehealth details",
      onClick: handleTelehealthDetailsRemove,
    },
  ];

  useEffect(() => {
    if (
      !selectedServices?.length ||
      selectedServices?.some(
        (service) => service.appointmentType !== AppointmentType.TELEHEALTH
      )
    ) {
      closeTelehealthDetailsSection();
      setTelehealthDetailsButtonDisabled(true);
    } else {
      setTelehealthDetailsButtonDisabled(false);
    }
  }, [
    closeTelehealthDetailsSection,
    setTelehealthDetailsButtonDisabled,
    selectedServices,
  ]);

  return (
    <Box
      sx={{
        mb: 8,
      }}
    >
      <Box>
        <Typography
          variant="labelTiny"
          sx={{
            color: TEXT_SECONDARY,
          }}
        >
          Client
        </Typography>
        <Autocomplete
          disablePortal
          popupIcon={<ChevronSmallDown color={VIOLET[90]} size={20} />}
          filterOptions={(options) => options}
          loading={isLoadingClients}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose client"
              variant="outlined"
              value={clientsSearchQuery}
              onChange={(event) => handleSearchClientsInput?.(event)}
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: <User1 color={GREY[50]} size={20} />,
                  size: "small",
                  inputRef: clientsSearchInputRef,
                },
              }}
            />
          )}
          onChange={(_, value) => onSelectClient(value)}
          value={client || null}
          slotProps={{
            paper: {
              sx: { boxShadow: shadows[4], borderRadius: 2, mt: 1 },
            },
          }}
          options={clients || []}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          renderOption={(props, option) => (
            <ListItem
              component="li"
              {...props}
              key={option.id}
              sx={{ px: "12px !important", py: "8px !important" }}
            >
              <ListItemText
                sx={{ m: 0 }}
                primary={`${option.firstName} ${option.lastName}`}
                secondary={formatPhoneNumber(option.phone, "dashed")}
                slotProps={{
                  primary: { variant: "labelSmall" },
                  secondary: {
                    variant: "labelTiny",
                    color: "text.secondary",
                  },
                }}
              />
            </ListItem>
          )}
        />
        <Button onClick={onCreateClient} sx={{ mt: 0.5 }}>
          <Typography variant="labelSmall">Create new client</Typography>
        </Button>
        <Divider orientation="horizontal" variant="fullWidth" sx={{ my: 2 }} />
      </Box>
      <Box>
        {isRebooking && (
          <AdditionalInfo
            variant="info"
            text="Services default to the same as today’s appointment."
            sx={{
              maxWidth: DEFAULT_CREATE_VISIT_DRAWER_WIDTH,
              my: 1,
            }}
          />
        )}
        <Typography
          variant="labelTiny"
          sx={{
            color: TEXT_SECONDARY,
          }}
        >
          Service(s)
        </Typography>
        <Autocomplete
          multiple
          value={selectedServices || []}
          disabled={!client}
          disablePortal
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) => disabledServiceIds.includes(option.id)}
          popupIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search for services..."
              variant="outlined"
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: <Search color={GREY[50]} size={20} />,
                  size: "small",
                  sx: {
                    backgroundColor: (theme) =>
                      params.disabled
                        ? GREY[15]
                        : theme.palette.background.paper,
                  },
                },
              }}
            />
          )}
          slotProps={{
            paper: {
              sx: { boxShadow: shadows[4], borderRadius: 2, mt: 1 },
            },
          }}
          onChange={(_, value) =>
            onSelectServices(
              value.map((s) => s.id),
              startDate
            )
          }
          options={servicesWithCategory ?? []}
          renderOption={(props, option, { index }) => (
            <RenderServiceOption
              key={option.id}
              service={option}
              withGfeIndicator
              ListItemProps={{
                ...props,
                key: index,
                component: "li",
                sx: { px: "12px !important", py: "8px !important" },
              }}
            />
          )}
          groupBy={(option) => option.categoryData.name}
          renderGroup={({ group, key, children }) => (
            <Stack key={key}>
              <ListSubheader disableSticky sx={{ height: "auto" }}>
                <Typography
                  variant="labelTiny"
                  sx={{
                    fontWeight: "bold",
                    color: TEXT_SECONDARY,
                  }}
                >
                  {group}
                </Typography>
              </ListSubheader>
              <List>{children}</List>
            </Stack>
          )}
        />
        <List>
          {(selectedServices?.length ?? 0) < 1 && (
            <ListItem
              sx={{
                backgroundColor: GREY[5],
                borderRadius: 2,
                gap: 2,
              }}
            >
              <ListItemText
                primary="No services added yet"
                slotProps={{
                  primary: {
                    variant: "labelSmall",
                    color: "text.secondary",
                    textAlign: "center",
                  },
                }}
              />
            </ListItem>
          )}
          {...(selectedServices ?? []).map((service) => (
            <RenderServiceOption
              key={service.id}
              service={service}
              onAction={() => onRemoveService(service.id)}
              withServicePrice
              withGfeIndicator
              TextProps={{
                slotProps: {
                  primary: {
                    color: VIOLET[90],
                    fontWeight: "600",
                  },
                },
              }}
              ListItemProps={{
                dense: true,
                disableGutters: true,
                sx: {
                  backgroundColor: VIOLET[20],
                  borderRadius: 2,
                  pl: 1,
                  [`&:not(:last-child)`]: {
                    mb: 1,
                  },
                  [`&:hover`]: {
                    backgroundColor: VIOLET[40],
                  },
                },
              }}
            />
          ))}
          {!areSelectedServicesSameApptType && (
            <AdditionalInfo
              variant="error"
              text="The services above include both in person and a telehealth format types. An appointment can only have one format type."
              sx={{
                maxWidth: DEFAULT_CREATE_VISIT_DRAWER_WIDTH,
                mt: 0,
              }}
            />
          )}
        </List>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          sx={{ mt: 1, mb: 2 }}
        />
      </Box>
      <Box>
        <Typography
          variant="labelTiny"
          sx={{
            color: TEXT_SECONDARY,
          }}
        >
          Provider
        </Typography>
        <Autocomplete
          disablePortal
          disabled={!client || !selectedServices?.length}
          popupIcon={
            <ChevronSmallDown
              color={
                !client || !selectedServices?.length ? GREY[40] : VIOLET[90]
              }
              size={20}
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose provider"
              variant="outlined"
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: <UserHeart color={GREY[50]} size={20} />,
                  size: "small",
                  sx: {
                    backgroundColor: (theme) =>
                      params.disabled
                        ? GREY[15]
                        : theme.palette.background.paper,
                  },
                },
              }}
            />
          )}
          slotProps={{
            paper: {
              sx: { boxShadow: shadows[4], borderRadius: 2, mt: 1 },
            },
          }}
          options={providers || []}
          value={!client || !selectedServices?.length ? null : provider || null}
          getOptionLabel={(option) => option.user.fullName}
          renderOption={(props, option) => (
            <ListItem
              component="li"
              key={option.id}
              {...props}
              sx={{ px: "12px !important", py: "8px !important" }}
            >
              <ListItemText
                sx={{ m: 0 }}
                primary={option.user.fullName}
                slotProps={{
                  primary: { variant: "labelSmall" },
                }}
              />
            </ListItem>
          )}
          onChange={(_, value) => {
            onSelectProvider(value || null);
          }}
        />
        <Divider orientation="horizontal" variant="fullWidth" sx={{ my: 2 }} />
      </Box>
      <Box>
        <Typography
          variant="labelTiny"
          sx={{
            color: TEXT_SECONDARY,
          }}
        >
          Select date & time
        </Typography>
        <TimeSelectorButton
          durationText=" based on selected service(s)"
          disabled={!client || !selectedServices?.length || !provider}
        />
        <Divider orientation="horizontal" variant="fullWidth" sx={{ my: 2 }} />
      </Box>
      {telehealthDetailsOpen ? (
        <Stack spacing={2}>
          <TelehealthDetailsSection
            telehealthDropdownActions={telehealthDropdownActions}
            telehealthType={telehealthType}
            telehealthVideoLink={telehealthVideoLink}
            telehealthAdditionalDetails={telehealthAdditionalDetails}
            onChangeTelehealthType={onChangeTelehealthType}
            onChangeTelehealthVideoLink={onChangeTelehealthVideoLink}
            onChangeTelehealthAdditionalDetails={
              onChangeTelehealthAdditionalDetails
            }
            onChangeIsTelehealthVisitDetailsValid={
              onChangeIsTelehealthVisitDetailsValid
            }
          />
          <TelehealthDetailsInfoBox />
        </Stack>
      ) : (
        <TelehealthDetailsSectionOpenButton
          onClick={handleTelehealthDetailsOpen}
          disabled={telehealthDetailsButtonDisabled}
        />
      )}
    </Box>
  );
};

export const TelehealthDetailsInfoBox = ({ sx }: { sx?: SxProps }) => (
  <AdditionalInfo
    variant="info"
    text="Telehealth details will be emailed to the client 1 hour before the appointment, and will be visible in their client portal."
    sx={{
      maxWidth: DEFAULT_CREATE_VISIT_DRAWER_WIDTH,
      ...sx,
    }}
  />
);

const TelehealthDetailsSectionOpenButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => (
  <OutlinedButton
    text="Add telehealth details"
    disabled={disabled}
    small
    onClick={onClick}
    customIcon={
      <PlusSmallIcon size={24} color={disabled ? GREY[40] : PRIMARY} />
    }
  />
);

const RenderServiceOption = ({
  service,
  secondaryText,
  withServicePrice = true,
  withGfeIndicator = false,
  onAction,
  ListItemProps,
  CloseButtonProps,
  TextProps,
  closeIcon = <CrossSmall color={VIOLET[90]} size={20} />,
}: {
  service: CategoriesWithClientIndicationsQuery["serviceCategory"][number]["medspaServiceMenuItems"][number];
  withServicePrice?: boolean;
  withGfeIndicator?: boolean;
  secondaryText?: string;
  TextProps?: Omit<ListItemTextProps, "primary" | "secondary">;
} & (
  | {
      onAction: VoidFunction;
      ListItemProps?: ListItemButtonProps;
      closeIcon?: React.ReactNode;
      CloseButtonProps?: IconButtonProps;
    }
  | {
      onAction?: never;
      ListItemProps?: ListItemProps;
      closeIcon?: never;
      CloseButtonProps?: never;
    }
)) => {
  const ListItemComponent = onAction ? ListItemButton : ListItem;
  const GfeIcon =
    GFE_SERVICE_STATUS_ICON[mapToGfeChipType[service.clientGfeIndication]];
  return (
    // @ts-expect-error -- no issues here.
    <ListItemComponent
      {...ListItemProps}
      sx={[
        {
          gap: 0.5,
          justifyContent: "space-between",
        },
        ...(Array.isArray(ListItemProps?.sx)
          ? ListItemProps.sx
          : [ListItemProps?.sx]),
      ]}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 0.5,
        }}
      >
        {service.appointmentType === AppointmentType.TELEHEALTH && (
          <CamIcon size={20} color={PRIMARY} />
        )}
        <ListItemText
          sx={{ m: 0 }}
          primary={service.name}
          secondary={secondaryText}
          {...TextProps}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "center",
        }}
      >
        {withServicePrice && (
          <Typography
            sx={{
              color: "text.secondary",
            }}
          >
            {service.isVariablePricing
              ? getVariablePricingCopy(service.price)
              : formattedPrice(service.price)}
          </Typography>
        )}
        {withGfeIndicator && GfeIcon && <GfeIcon />}
        {onAction && (
          <IconButton onClick={onAction} color="primary" {...CloseButtonProps}>
            {closeIcon}
          </IconButton>
        )}
      </Stack>
    </ListItemComponent>
  );
};
