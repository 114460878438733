import { useRouter } from "next/router";
import { useEffect } from "react";
import { UrlPermission } from "@/types/urlPermission";

export function useGuardUrlPermissionOnRouteChange(
  newPermissionsEnabledForUser: boolean,
  hasUrlPermission: (url: UrlPermission) => boolean,
  urlPermissions: string[]
) {
  const router = useRouter();

  useEffect(() => {
    // Check permissions on route changes
    const handleRouteChange = (url: string) => {
      if (urlPermissions.length === 0 || !newPermissionsEnabledForUser) {
        // loading guards
        return;
      }
      if (url === "/403") {
        return;
      }

      if (newPermissionsEnabledForUser && !hasUrlPermission(url)) {
        router.push("/403");
      }
    };

    // Check permissions on initial load
    if (urlPermissions.length > 0) {
      handleRouteChange(router.asPath);
    }

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [newPermissionsEnabledForUser, hasUrlPermission, urlPermissions, router]);
}
